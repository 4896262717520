import React, { useState, useMemo } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  useColorMode,
  Link,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  BoxProps,
  FlexProps,
  keyframes,
  useBreakpointValue,
  Stack,
  Button,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Collapse,
} from '@chakra-ui/react';
import {
  FiChevronsLeft,
  FiSettings,
  FiInfo,
  FiChevronUp,
  FiChevronDown,
  FiLock,
} from 'react-icons/fi';
import useAuth from '../../hooks/useAuth';
import logoText from '../../assets/PriceSmith-Logo-Text-DesignX.svg';
import logoTextInverse from '../../assets/Pricesmith-Logo-Text-Dark-DesignX.svg';
import { logAnalyticsEvent } from '../../firebase';
import { StyledMultiSelect } from '../StyledMultiSelect';
import { IconType } from 'react-icons/lib';
import { CustomUser } from '../../context/FirebaseContext';
import { ChevronRightIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { LinkItem } from '../../../@types/FrontendViewModels';

const LinkItems: LinkItem[] = [
  {
    name: 'Dashboard',
    location: '/',
  },
  {
    name: 'Pricing',
    location: '/pricing',
    setting: 'listmanager',
    accessibleRoles: ['listmanager'],
  },
  {
    name: 'Sales',
    location: '/sales',
    setting: 'accountmanager',
    accessibleRoles: ['accountmanager'],
  },
  {
    name: 'Assets',
    location: '/assets',
    setting: 'pricesheets',
  },
];

interface SidebarWithHeaderProps extends React.PropsWithChildren {
  // hideAccountContext?: boolean; // Removed since it's no longer used
  sidebarItems?: LinkItem[];
  topElement?: JSX.Element;
}

const isSettingEnabled = (link: LinkItem, user: CustomUser | null) =>
  !link.setting || !!(link.setting && user?.settings?.[link.setting]?.enabled);

const isUserLinkAccessible = (link: LinkItem, user: CustomUser | null) =>
  !link.accessibleRoles ||
  (link.accessibleRoles &&
    link.accessibleRoles?.some(r => user?.roles?.includes(r)));

const isLinkValid = (link: LinkItem, user: CustomUser | null) => {
  if (link.accessibleRoles && link.setting) {
    return (
      link.accessibleRoles &&
      link.accessibleRoles?.some(r => user?.roles?.includes(r)) &&
      link.setting &&
      user?.settings?.[link.setting]?.enabled
    );
  } else {
    return (
      (!link.accessibleRoles && !link.setting) ||
      (link.accessibleRoles &&
        link.accessibleRoles?.some(r => user?.roles?.includes(r))) ||
      (link.setting && user?.settings?.[link.setting]?.enabled)
    );
  }
};

export default function Layout({
  sidebarItems,
  topElement,
  children,
}: SidebarWithHeaderProps) {
  // If 'UserSidebarState' isn't available the user hasn't manually toggled before, so start as open

  const userSidebarState =
    (localStorage.getItem('UserSidebarState') ?? 'true') === 'true';

  const { isOpen: isSidebarOpen, onToggle: onSidebarToggle } = useDisclosure({
    defaultIsOpen: userSidebarState,
  });
  const NAV_HEIGHT = 'var(--chakra-space-12)';
  const SIDEBAR_OPENED_WIDTH = 'var(--chakra-space-60)';
  const SIDEBAR_CLOSED_WIDTH = 'var(--chakra-space-15)';
  const TRANSITION = '0.2s ease';

  const handleSidebarToggle = React.useCallback(() => {
    // isSidebarOpen will still be in the current state, before the toggle, so reverse it before setting localStorage
    localStorage.setItem('UserSidebarState', `${!isSidebarOpen}`);
    onSidebarToggle();
  }, [isSidebarOpen, onSidebarToggle]);

  return (
    <Flex bg={useColorModeValue('gray.50', 'gray.900')} wrap={'wrap'}>
      <Header />

      <Box
        height={`calc(100vh - ${NAV_HEIGHT})`}
        overflowY={'auto'}
        w="full"
        display="flex"
      >
        {sidebarItems && sidebarItems.length > 0 && (
          <>
            <SidebarContent
              w={isSidebarOpen ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH}
              zIndex={9}
              overflow={'hidden'}
              transition={TRANSITION}
              isSidebarOpen={isSidebarOpen}
              onSidebarToggle={handleSidebarToggle}
              animation={TRANSITION}
              sidebarItems={sidebarItems}
            />
          </>
        )}
        <Box
          p={0}
          transition={
            sidebarItems && sidebarItems.length > 0
              ? `margin ${TRANSITION}`
              : 'none'
          }
          ml={
            sidebarItems && sidebarItems.length > 0
              ? isSidebarOpen
                ? SIDEBAR_OPENED_WIDTH
                : SIDEBAR_CLOSED_WIDTH
              : {}
          }
          w={'full'}
          h="full"
          textAlign="center"
          fontSize="xs"
        >
          {topElement}
          {children}
        </Box>
      </Box>
    </Flex>
  );
}

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const LOGO_WITH_TEXT = useColorModeValue(logoText, logoTextInverse);
  const { logout, user, updateTenantId } = useAuth();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.300', 'gray.800');
  const hoverColor = useColorModeValue('gray.200', 'gray.700');
  const NAV_HEIGHT = 'var(--chakra-space-12)';
  const LOGO_CONTAINER_WIDTH = 'var(--chakra-space-48)';
  const LOGO_CONTAINER_WIDTH_MOBILE = 'var(--chakra-space-30)';

  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'space-between' }}
      position={'sticky'}
      px={4}
      left={0}
      top={0}
      width={'100%'}
      h={NAV_HEIGHT}
      zIndex={99}
    >
      <Flex h={12} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          <Box
            w={{
              md: LOGO_CONTAINER_WIDTH,
              base: LOGO_CONTAINER_WIDTH_MOBILE,
            }}
          >
            <Image
              maxH={8}
              src={LOGO_WITH_TEXT}
              onClick={() => navigate('/')}
              _hover={{ cursor: 'pointer' }}
            />
          </Box>
          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            {LinkItems.map((link, i) => {
              const settingEnabled = isSettingEnabled(link, user);
              return renderLinkItem(link, i, user, !!isMobile, settingEnabled);
            })}
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          <Stack direction={'row'} spacing={2}>
            <Button>
              <FiInfo />
            </Button>

            <Button>
              <FiSettings />
            </Button>

            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar size={'sm'} src={user?.photoURL ?? undefined} />
              </MenuButton>

              <MenuList alignItems={'center'}>
                <Center>
                  <Avatar size={'md'} src={user?.photoURL ?? undefined} />
                </Center>

                <Center flexWrap={'wrap'} textAlign={'center'} mt={2}>
                  <Text fontSize="sm" width={'full'}>
                    {user?.displayName}
                  </Text>
                  <Text fontSize="xs" width={'full'} color="gray.600">
                    {user?.isAdmin ? 'Admin' : 'User'}
                  </Text>
                </Center>

                <MenuDivider />

                <Link
                  as={RouterLink}
                  to={'/profile'}
                  style={{ textDecoration: 'none' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  <MenuItem bg={bgColor} _hover={{ bg: hoverColor }}>
                    Profile
                  </MenuItem>
                </Link>
                <MenuItem isDisabled>Settings</MenuItem>
                <MenuItem bg={bgColor} isDisabled>
                  Billing
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  bg={bgColor}
                  _hover={{ bg: hoverColor }}
                  onClick={() => {
                    toggleColorMode();
                    logAnalyticsEvent('color_mode_changed', {
                      firebase_screen: user?.email,
                    });
                  }}
                >
                  {colorMode === 'light' ? 'Dark Theme' : 'Light Theme'}
                </MenuItem>
                <MenuItem bg={bgColor} _hover={{ bg: hoverColor }}>
                  <Link
                    href={
                      'https://docs.google.com/forms/d/e/1FAIpQLSdKYO7JgC-0K33KUjFQSD3ZNjixrOo9S2RtC9nB-c0ZZmh5AQ/viewform'
                    }
                    isExternal
                  >
                    {'Send Feedback'}
                  </Link>
                </MenuItem>
                {user?.roles?.includes('admin') && (
                  <Link
                    as={RouterLink}
                    to={'/admin'}
                    style={{ textDecoration: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                  >
                    <MenuItem bg={bgColor} _hover={{ bg: hoverColor }}>
                      Admin Tools
                    </MenuItem>
                  </Link>
                )}

                <MenuDivider />
                <MenuItem
                  bg={bgColor}
                  _hover={{ bg: hoverColor }}
                  onClick={() => logout()}
                >
                  Sign out
                </MenuItem>
                <MenuDivider />
                {user?.roles?.includes('globaladmin') && user?.tenant_ids && (
                  <Box px={4}>
                    <StyledMultiSelect
                      isClearable={false}
                      name="tenants"
                      defaultValue={{
                        value: user?.tenant_id,
                        label: user?.tenantSelectInfo.find(
                          x => x.tenantId === user?.tenant_id
                        )?.tenantName,
                      }}
                      options={user?.tenantSelectInfo?.map(x => {
                        return { value: x.tenantId, label: x.tenantName };
                      })}
                      placeholder="Select tenant"
                      closeMenuOnSelect={true}
                      onChange={selectedOption => {
                        const val = selectedOption as {
                          value: string | null | undefined;
                        };
                        console.warn('set tenant ' + val.value);
                        if (updateTenantId && val.value) {
                          updateTenantId(val.value);
                        }
                      }}
                    />
                  </Box>
                )}
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box
          pb={4}
          display={{ md: 'none' }}
          bg={bgColor}
          border={'1px'}
          borderColor={borderColor}
          borderRadius={5}
          zIndex={999}
        >
          <Stack as={'nav'} spacing={0}>
            {LinkItems.map((link, i) => {
              const settingEnabled = isSettingEnabled(link, user);
              return renderLinkItem(link, i, user, !!isMobile, settingEnabled);
            })}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

interface SidebarContentProps extends BoxProps {
  isSidebarOpen?: boolean;
  onSidebarToggle?: () => void;
  sidebarItems?: LinkItem[];
}

const SidebarContent = ({
  isSidebarOpen,
  onSidebarToggle,
  animation,
  sidebarItems,
  ...rest
}: SidebarContentProps) => {
  const { user } = useAuth();
  const HEADER_CONTAINER_HEIGHT = 'var(--chakra-space-20)';
  const FOOTER_CONTAINER_HEIGHT = 'var(--chakra-space-10)';
  const LINKS_CONTAINER_HEIGHT = `calc(100vh - ${HEADER_CONTAINER_HEIGHT} - ${FOOTER_CONTAINER_HEIGHT})`;
  const ROTATE_BACK = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(180deg); }
  `;
  const ROTATE_FORWARD = keyframes`
    from { transform: rotate(180deg); }
    to { transform: rotate(0deg); }
  `;
  const sidebarHeaderColor = useColorModeValue('gray.500', 'gray.500');
  const scrollbarColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Box
        display={'block'}
        w={'full'}
        position={'sticky'}
        bottom={0}
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Flex
          h={FOOTER_CONTAINER_HEIGHT}
          alignItems={'center'}
          justifyContent={'end'}
        >
          <IconButton
            h={8}
            w={8}
            mr={3}
            fontSize={'3xl'}
            icon={<FiChevronsLeft />}
            aria-label={'Collapse sidebar'}
            color="gray.600"
            animation={`${
              isSidebarOpen ? ROTATE_FORWARD : ROTATE_BACK
            } ${animation} forwards`}
            onClick={onSidebarToggle}
            _hover={{ cursor: 'pointer' }}
          />
        </Flex>
      </Box>
      <Box
        h={LINKS_CONTAINER_HEIGHT}
        bg={useColorModeValue('white', 'gray.800')}
        overflow={'hidden'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarColor,
            borderRadius: '24px',
          },
        }}
      >
        {/** When in md size or larger collapsible links aren't visible when the sidebar is closed */}

        <Stack p={2}>
          {sidebarItems?.map((item, index) =>
            isLinkValid(item, user) ? (
              <div key={index}>
                {item.location ? (
                  <NavItem
                    key={item.name}
                    location={item.location}
                    icon={item.icon}
                    isSidebarOpen={isSidebarOpen}
                    tooltipLabel={item.name}
                    style={{
                      fontSize: '14px',
                      borderRadius: 2,
                      paddingTop: isSidebarOpen ? 4 : 13,
                      paddingBottom: isSidebarOpen ? 4 : 13,
                      paddingLeft: isSidebarOpen ? 8 : 13,
                      paddingRight: isSidebarOpen ? 8 : 13,
                    }}
                  >
                    <Stack direction="row" align="center" spacing={2}>
                      {isSidebarOpen && <Text>{item.name}</Text>}
                    </Stack>
                  </NavItem>
                ) : (
                  <Stack spacing={2} px={3} mt={5} mb={2}>
                    {isSidebarOpen && (
                      <Text
                        fontSize={'sm'}
                        fontWeight="medium"
                        color={sidebarHeaderColor}
                      >
                        {item.name}
                      </Text>
                    )}
                  </Stack>
                )}
                {item.children && (
                  <Stack spacing={2}>
                    {item.children.map((child, childIndex) =>
                      isLinkValid(child, user) ? (
                        <NavItem
                          key={childIndex}
                          location={child.location}
                          icon={child.icon}
                          isSidebarOpen={isSidebarOpen}
                          tooltipLabel={child.name}
                          disableMenu={child.isDisabled}
                          style={{
                            fontSize: '14px',
                            borderRadius: 2,
                            paddingTop: isSidebarOpen ? 4 : 13,
                            paddingBottom: isSidebarOpen ? 4 : 13,
                            paddingLeft: isSidebarOpen ? 8 : 13,
                            paddingRight: isSidebarOpen ? 8 : 13,
                          }}
                        >
                          <Stack direction="row" align="center" spacing={2}>
                            {isSidebarOpen && <Text>{child.name}</Text>}
                          </Stack>
                        </NavItem>
                      ) : null
                    )}
                  </Stack>
                )}
              </div>
            ) : null
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const renderLinkItem = (
  link: LinkItem,
  i: number,
  user: CustomUser | null,
  isMobile: boolean,
  settingEnabled?: boolean
) => {
  const userLinkAccessible = isUserLinkAccessible(link, user);

  if (settingEnabled != undefined && !settingEnabled) {
    return (
      <NavItem
        style={{
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 12,
          paddingRight: 12,
        }}
        key={link.name}
        location={link.location}
        settingEnabled={false}
      >
        <Text fontSize={'sm'} noOfLines={1}>
          {link.name}
        </Text>
      </NavItem>
    );
  }

  if (userLinkAccessible) {
    if ((link.children?.length ?? 0) > 0) {
      return (
        <CollapsableNavItem
          key={i}
          link={link}
          i={i}
          user={user}
          isMobile={isMobile}
        />
      );
    } else {
      return (
        <NavItem
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 12,
            paddingRight: 12,
          }}
          key={link.name}
          location={link.location}
        >
          <Text fontSize={'sm'} noOfLines={1}>
            {link.name}
          </Text>
        </NavItem>
      );
    }
  }
};

const CollapsableNavItem = ({
  link,
  i,
  isMobile,
  user,
}: {
  link: LinkItem;
  i: number;
  isMobile: boolean;
  user: CustomUser | null;
}) => {
  const hoverBg = useColorModeValue('gray.200', 'gray.700');
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <div key={i}>
      {!isMobile ? (
        <Popover placement={'bottom-start'} trigger={'hover'}>
          <PopoverTrigger>
            <Text fontSize={'sm'} noOfLines={1}>
              {link.name}
            </Text>
          </PopoverTrigger>

          <PopoverContent
            border={0}
            boxShadow={'md'}
            p={4}
            rounded={'xl'}
            minW={'min-content'}
          >
            <Stack>
              {link.children?.map((x, i) => {
                if (x.children && x.children.length !== 0) {
                  return (
                    <Popover key={i} placement="right" trigger="hover">
                      <PopoverTrigger>
                        <Text
                          fontSize={'sm'}
                          noOfLines={1}
                          px={2}
                          py={2}
                          w="full"
                          display={'flex'}
                        >
                          {x.name}
                          <Icon
                            color={'gray.400'}
                            w={5}
                            h={5}
                            ml="auto"
                            as={ChevronRightIcon}
                          />
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent
                        border={0}
                        boxShadow={'md'}
                        p={4}
                        rounded={'xl'}
                        minW={'min-content'}
                      >
                        {link.children?.map((x, i) => {
                          return (
                            <NavItem key={i} location={x.location} padding="2">
                              <Text fontSize={'sm'} noOfLines={1}>
                                {x.name}
                              </Text>
                            </NavItem>
                          );
                        })}
                      </PopoverContent>
                    </Popover>
                  );
                }
                return (
                  <NavItem key={i} location={x.location} padding="2">
                    <Text fontSize={'sm'} noOfLines={1}>
                      {x.name}
                    </Text>
                  </NavItem>
                );
              })}
            </Stack>
          </PopoverContent>
        </Popover>
      ) : (
        <>
          <Flex
            align="center"
            justifyContent="space-between"
            p="1"
            pl={link.padding ?? 3}
            mt={1}
            role="group"
            cursor="pointer"
            _hover={{
              bg: hoverBg,
            }}
            onClick={() => handleToggle()}
          >
            <Text fontSize={'sm'} noOfLines={1}>
              {link.name}
            </Text>
            <Icon h={4} w={4} as={show ? FiChevronUp : FiChevronDown} />
          </Flex>
          <Collapse in={show}>
            {link.children?.map((x, i) => renderLinkItem(x, i, user, true))}
          </Collapse>
        </>
      )}
    </div>
  );
};

interface NavItemProps extends FlexProps, React.PropsWithChildren {
  location: string | undefined;
  disableMenu?: boolean;
  settingEnabled?: boolean;
  icon?: IconType | undefined;
  tooltipLabel?: string;
  isSidebarOpen?: boolean;
}

const NavItem = ({
  icon,
  children,
  location,
  settingEnabled,
  isSidebarOpen,
  tooltipLabel,
  disableMenu,
  ...rest
}: NavItemProps) => {
  const routerLocation = useLocation();
  const hoverBg = useColorModeValue('gray.200', 'gray.700');
  const activeBg = useColorModeValue('gray.100', 'gray.700');
  const params = useParams();

  // const isActive = location && routerLocation.pathname === location; // TODO - Backup

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (settingEnabled !== undefined && !settingEnabled) {
        event.preventDefault();
      }
    },
    [settingEnabled]
  );

  const isActive = useMemo(() => {
    if (!location || location === '/') {
      return false;
    }

    const hasPipelineInMenu =
      tooltipLabel?.includes('Pipeline') ||
      tooltipLabel?.includes('Price Sheets');

    if (
      !icon &&
      (routerLocation.pathname.includes(location) ||
        location === routerLocation.pathname)
    ) {
      return true;
    }

    if (
      (icon &&
        routerLocation.pathname.includes(location) &&
        !hasPipelineInMenu) ||
      ((routerLocation.pathname === location || params?.branchId) &&
        hasPipelineInMenu)
    ) {
      return true;
    }

    return false;
  }, [icon, location, params?.branchId, routerLocation.pathname, tooltipLabel]);

  return (
    <Link
      as={RouterLink}
      to={location ?? ''}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={e => handleClick(e)}
    >
      <Flex
        align="center"
        bg={isActive ? activeBg : undefined}
        role="group"
        cursor={
          (settingEnabled !== undefined && !settingEnabled) || disableMenu
            ? 'default'
            : 'pointer'
        }
        _hover={{
          bg:
            (settingEnabled !== undefined && !settingEnabled) || disableMenu
              ? 'inherit'
              : hoverBg,
        }}
        borderRadius={{ base: 0, md: 2 }}
        opacity={
          (settingEnabled !== undefined && !settingEnabled) || disableMenu
            ? 0.3
            : 1
        }
        {...rest}
      >
        {icon && isSidebarOpen && <Icon mr={3} h={4} w={4} as={icon} />}
        {icon && !isSidebarOpen && (
          <Tooltip hasArrow label={tooltipLabel} placement="right-start">
            <Box display={'inline'} height={4}>
              <Icon h={4} w={4} as={icon} />
            </Box>
          </Tooltip>
        )}
        <Box as={isActive ? 'b' : undefined}>{children}</Box>
        {settingEnabled !== undefined && !settingEnabled && (
          <Icon ml={2} mr={3} h={4} w={4} as={FiLock} />
        )}
      </Flex>
    </Link>
  );
};
