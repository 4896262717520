import { format } from 'timeago.js';
import {
  ComparisonResult,
  PriceSheetDownload,
} from '../../@types/FrontendViewModels';
import { AxiosResponse } from 'axios';
import {
  CreateLmSegmentItemOverrideDto,
  ProductPriceChangePayload,
} from '../../@types/BackendViewModels';
import { PriceSheetVMNew } from '../../@types/DotnetifyViewModels';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const getCurrentUtcDateTime = () => {
  const local = new Date();
  return new Date(
    local.getUTCFullYear(),
    local.getUTCMonth(),
    local.getUTCDate(),
    local.getUTCHours(),
    local.getUTCMinutes(),
    local.getUTCSeconds()
  );
};

export const getTimeAgoFromUtc = (date: Date) => {
  return format(date + ' UTC');
};

export function isSameNestedValueForAllObjects<
  T,
  K extends keyof T,
  L extends keyof T[K],
  M extends keyof T[K][L]
>(
  array: T[],
  levelOneKey: K,
  levelTwoKey: L,
  levelThreeKey: M
): ComparisonResult<T[K][L][M]> {
  if (array.length === 0) {
    return { isSame: false, commonValue: null }; // Adjust based on how you want to handle empty arrays
  }

  // Get the value of the first object in the array at the third level
  const firstItemValue = array[0][levelOneKey][levelTwoKey][levelThreeKey];

  // Check if every object has the same value at the third level
  const isSame = array.every(
    obj => obj[levelOneKey][levelTwoKey][levelThreeKey] === firstItemValue
  );

  return {
    isSame: isSame,
    commonValue: isSame ? firstItemValue : null, // If values are not the same, we return null, or adjust as needed
  };
}

export const getFilenameFromContentDisposition = (
  contentDisposition: string
) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return '';
};

// eslint-disable-next-line
export const downloadFileFromAxiosResponse = (res: AxiosResponse<any, any>) => {
  const filename = getFilenameFromContentDisposition(
    res.headers['content-disposition']
  );

  const url = window.URL.createObjectURL(res.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); // Set the filename
  document.body.appendChild(link);
  link.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const getBadgeColorCustomerStage = (stage: string | undefined) => {
  switch (stage?.toUpperCase()) {
    case 'LEAD':
      return 'purple';
    case 'PROSPECT':
      return 'twitter';
    case 'CUSTOMER':
      return 'gray';
    default:
      return 'gray';
  }
};

export const getItemOverrideFromRequestedPrice = ({
  newPrice,
  item,
}: {
  newPrice: number;
  item: ProductPriceChangePayload;
}): CreateLmSegmentItemOverrideDto => {
  const { segmentId, referencePrice, cost, priceCalculationType } = item;
  const itemIid = item?.itemIid;

  if (priceCalculationType.toString() == '4') {
    return {
      itemIid: itemIid,
      priceCalculationType: 'FixedPrice',
      segmentId: segmentId,
      priceCalculationModifier: newPrice,
    };
  }
  // Ensure the referencePrice is not zero to avoid division by zero error
  if (!referencePrice || referencePrice === 0) {
    //Do cost +
    if (!cost || cost === 0) {
      return {
        itemIid: itemIid,
        priceCalculationType: 'FixedPrice',
        segmentId: segmentId,
        priceCalculationModifier: newPrice,
      };
    }
    // Calculate the amount that needs to be added to the cost to reach the desired price
    const increaseAmount = newPrice - cost;

    // Calculate the markup percentage
    const markupPercentage = parseFloat(
      ((increaseAmount / cost) * 100).toFixed(3)
    );

    return {
      itemIid: itemIid,
      priceCalculationType: 'CostPlus',
      segmentId: segmentId,
      priceCalculationModifier: markupPercentage,
    };
  } else {
    // Calculate the discount amount
    const discountAmount = referencePrice - newPrice;
    const discountPercent = -((discountAmount / referencePrice) * 100);
    // Calculate the discount percentage
    const discountPercentage = parseFloat(discountPercent.toFixed(3));

    return {
      itemIid: itemIid,
      priceCalculationType: 'ListDiscount',
      segmentId: segmentId,
      priceCalculationModifier: discountPercentage,
    };
  }
};

export const formatPrice = (value: string | null | undefined | number) => {
  // Check if value is not null or undefined
  if (value !== null && value !== undefined && value !== '') {
    // Convert to percent, fix to 2 decimal places, and add percent sign
    const dollars = '$' + Number(value).toFixed(2);
    // Format with commas for thousands, etc.

    return dollars.replace(/\d(?=(\d{3})+(\.\d{2})?$)/g, '$&,');
  }

  return null;
};

export const formatPercent = (value: number | null | undefined) => {
  // Check if value is not null or undefined
  if (value !== null && value !== undefined) {
    // Convert to percent, fix to 2 decimal places, and add percent sign
    const percentage = (value * 100).toFixed(1) + '%';
    // Format with commas for thousands, etc.
    return percentage.replace(/\d(?=(\d{3})+\.\d{2})/g, '$&,');
  }
  return null;
};

export const formatNumber = (value: string | null | undefined | number) => {
  // Check if value is not null or undefined
  if (value !== null && value !== undefined && value !== '') {
    // Convert to percent, fix to 2 decimal places, and add percent sign
    const number = Number(value).toString();
    // Format with commas for thousands, etc.

    return number.replace(/\d(?=(\d{3})+(\.\d{2})?$)/g, '$&,');
  }

  return null;
};

export const getPriceSheetDownloads = (state: PriceSheetVMNew) => {
  const priceSheetDownloads: PriceSheetDownload[] = [];
  state.HydratedHtmlTemplates.map(x => {
    const template = state.Templates.find(y => y.Id === x.TemplateId);
    const brand = state.Brands.find(y => y.Id === template?.BrandId);
    const businessUnitName = state.BusinessUnit?.BusinessUnitName;
    return priceSheetDownloads.push({
      hydratedHtmlTemplate: x.HydratedHtml,
      templateName: template ? template.TemplateName : 'Clearance',
      templateId: template ? template.Id : 0,
      landscapeOrientation: template ? template.LandscapeOrientation : false,
      customerName: state.Account?.Customer_Name,
      customerIID: state.Account?.Customer_IID,
      customerEID: state.Account?.Customer_EID,
      price_PriceLevel_Name: state.Account?.Price_PriceLevel_Name,
      branchName: state.Account?.Branch_Name,
      brandName: brand?.BrandName,
      businessUnitName: businessUnitName,
    });
  });

  return priceSheetDownloads;
};
