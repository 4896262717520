import React from 'react';
import Layout from '../../components/navigation/Layout';
import { FiClipboard, FiDollarSign, FiFolder, FiList } from 'react-icons/fi';
import { LinkItem } from '../../../@types/FrontendViewModels';
import { ListManagerProvider } from '../../hooks/useListManager';
import AccountContextControls from '../../components/misc/AccountContextControls';
import PageTopElementBox from '../../components/misc/PageTopElementBox';

interface AssetsDashboardProps extends React.PropsWithChildren {
  showContext: boolean;
}
const AssetsDashboard = ({ showContext, children }: AssetsDashboardProps) => {
  return (
    <ListManagerProvider>
      <AssetsDashboardInner showContext={showContext}>
        {children}
      </AssetsDashboardInner>
    </ListManagerProvider>
  );
};

const AssetsDashboardInner = ({
  showContext,
  children,
}: AssetsDashboardProps) => {
  const sidebar = React.useMemo<LinkItem[]>(() => {
    return [
      {
        name: 'Price Sheets',
        location: '/assets',
        icon: FiDollarSign,
      },
      {
        name: 'Distribute',
        location: '/assets/distribute',
      },
      {
        name: 'Manage',
        accessibleRoles: ['admin'],
        children: [
          {
            name: 'Price Tables',
            icon: FiList,
            location: '/assets/manage/priceTables',
            padding: '8',
          },
          {
            name: 'Templates',
            icon: FiFolder,
            location: '/assets/manage/templates',
            padding: '8',
          },
          {
            name: 'Brands',
            icon: FiClipboard,
            location: '/assets/manage/brands',
            padding: '8',
          },
          {
            name: 'Business Units',
            icon: FiClipboard,
            location: '/assets/manage/businessUnits',
            padding: '8',
          },
        ],
      },
      {
        name: 'Layouts',
        accessibleRoles: ['admin'],
        children: [
          {
            name: 'Layouts',
            location: '/assets/manage/layouts',
            padding: '12',
          },
          { name: 'Styles', location: '/assets/manage/styles', padding: '12' },
          {
            name: 'Headers',
            location: '/assets/manage/headers',
            padding: '12',
          },
          { name: 'Tables', location: '/assets/manage/tables', padding: '12' },
          {
            name: 'Template Tables',
            location: '/assets/manage/templateTables',
            padding: '12',
          },
          {
            name: 'Salesperson',
            location: '/assets/manage/salespersons',
            padding: '12',
          },
          {
            name: 'Footers',
            location: '/assets/manage/footers',
            padding: '12',
          },
        ],
      },
    ];
  }, []);

  const topElement = React.useMemo(
    () => (
      <PageTopElementBox>
        <AccountContextControls />
      </PageTopElementBox>
    ),
    []
  );

  return (
    <Layout
      sidebarItems={sidebar}
      topElement={showContext ? topElement : undefined}
    >
      {children}
    </Layout>
  );
};

export default AssetsDashboard;
