import {
  MutationOptions,
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  updateProductDataManager,
  getItemsProperties,
  getGroupProperties,
  getProductAndSchemaDto,
  getItemProperties,
  createSchemaLibraryField,
  updateSchemaLibraryField,
  importDataFromBlob,
  updateProductsBatch, updateProductOrderId,
} from './productDataManager.endpoints';
import { useParams } from 'react-router';
import useAuth from '../../hooks/useAuth';
import {
  CreateSchemaFieldDefinitionDto,
  MapProperty,
  SchemaFieldDefinitionDto,
  UpdateSchemaFieldDefinitionDto,
} from '../../../@types/BackendViewModels';
import { useToast } from '@chakra-ui/toast';
import { getProductDataSchemaLibraryColumns, getSchemaLibrary } from '../SchemaLibrary/schemaLibrary.endpoints';
import { AxiosError } from 'axios';
import { UpdateProductBatch } from './productDataManager.models';

const baseKey = process.env.REACT_APP_URL;

export const useUpdateProductDataManager = () => {
  const client = useQueryClient();
  const params = useParams();
  const { user } = useAuth();
  const { mutateAsync, isPending, isError } = useMutation({
    mutationKey: [updateProductDataManager.name],
    mutationFn: (variables: {
      value: { [key: string]: string | object | null };
      branchId: number | undefined;
      accessToken: string | undefined;
      products_Group?: string | undefined;
    }) => {
      return updateProductDataManager.query(
        variables.value,
        variables.branchId,
        variables.accessToken
      );
    },
    onSuccess: data => {
      const invalidKey = [
        `${baseKey}.${getItemsProperties.name}`,
        user?.accessToken,
        params.products_Group,
      ];
      setTimeout(() => {
        void client.invalidateQueries({ queryKey: invalidKey });
      }, 500);
      return data;
    },
  });

  return {
    updatingProductDataManager: isPending,
    updateProductDataManagerAsync: mutateAsync,
    isUpdatingError: isError,
  };
};

export const useGetItemProperties = (
  accessToken: string | undefined,
  branchId: number
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getItemProperties.name}`, accessToken, branchId],
    queryFn: getItemProperties.query,
  });

  return {
    isLoading,
    isFetching,
    refetch,
    isError,
    data,
  };
};

export const useGetItemsProperties = (
  accessToken: string | undefined,
  branchId: number,
  products_Group?: string | undefined,
  columnsList?: string[] | undefined
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [
      `${baseKey}.${getItemsProperties.name}`,
      accessToken,
      products_Group,
      columnsList,
      branchId,
    ],
    queryFn: getItemsProperties.query,
  });

  return {
    itemPropertiesLoading: isLoading,
    isItemPropertiesFetching: isFetching,
    refetchItemProperties: refetch,
    isError,
    itemProperties: data,
  };
};

export const useGetGroupProperties = (
  accessToken: string | undefined,
  branchId: number | undefined
) => {
  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getGroupProperties.name}`, accessToken, branchId],
    queryFn: getGroupProperties.query,
    enabled: !!accessToken,
    staleTime: Infinity,
  });

  return {
    groupPropertiesLoading: isLoading,
    groupPropertiesFetching: isFetching,
    refetchGroupProperties: refetch,
    isError,
    groupProperties: data,
  };
};

export const useGetProductDataWrapperRecords = (
  branchId: number | undefined,
  accessToken: string | undefined
) => {
  const queryFn = ({
    queryKey,
    meta,
    signal,
  }: QueryFunctionContext<[string, number | undefined]>) => {
    return getProductAndSchemaDto.query(
      { queryKey: queryKey, signal: signal, meta: meta },
      accessToken
    );
  };

  const { isLoading, isError, isFetching, data, refetch } = useQuery({
    queryKey: [`${baseKey}.${getProductAndSchemaDto.name}`, branchId],
    queryFn, // Use the adjusted queryFn
    enabled: !!branchId && !!accessToken, // Ensure the query runs only if both segmentId and accessToken are available
    staleTime: Infinity,
  });

  return { isLoading, isError, isFetching, data, refetch };
};

export const useCreateSchemaLibraryField = (
  config: MutationOptions<SchemaFieldDefinitionDto, Error, unknown> = {}
) => {
  const { onSuccess } = config;
  const client = useQueryClient();
  const { user } = useAuth();
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [createSchemaLibraryField.name],
    mutationFn: (variables: { definition: CreateSchemaFieldDefinitionDto }) => {
      return createSchemaLibraryField.query(
        variables.definition,
        user?.accessToken ?? undefined
      );
    },
    onSuccess: (result, error, variable) => {
      const invalidateSchemaLibrary= [`${baseKey}.${getProductDataSchemaLibraryColumns.name}`];

      const invalidateQuery = [
        `${baseKey}.${getSchemaLibrary.name}`,
        'product',
        user?.accessToken,
      ];

      void client.invalidateQueries({ queryKey: invalidateQuery });
      void client.invalidateQueries({ queryKey: invalidateSchemaLibrary });

      toast({
        description: 'Created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
      onSuccess?.(result, error, variable);
    },
    onError: (error: AxiosError) => {
      toast({
        description:
          error.response?.data?.toString() ??
          'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    onAddDefinitionPending: isPending,
    onAddDefinition: mutateAsync,
    onAddDefinitionError: isError,
    onAddDefinitionSuccess: isSuccess,
  };
};

export const useUpdateSchemaLibraryField = (
  config: MutationOptions<SchemaFieldDefinitionDto, Error, unknown> = {}
) => {
  const { onSuccess } = config;
  const client = useQueryClient();
  const { user } = useAuth();
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [updateSchemaLibraryField.name],
    mutationFn: (definition: UpdateSchemaFieldDefinitionDto) => {
      return updateSchemaLibraryField.query(
        definition,
        user?.accessToken ?? undefined
      );
    },
    onSuccess: (result, error, variable) => {
      const invalidateQuery = [
        `${baseKey}.${getSchemaLibrary.name}`,
        'product',
        user?.accessToken,
      ];

      void client.invalidateQueries({ queryKey: invalidateQuery });
      toast({
        description: 'Updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
      onSuccess?.(result, error, variable);
    },
    onError: (error: AxiosError) => {
      toast({
        description:
          error.response?.data?.toString() ??
          'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    onUpdateDefinitionPending: isPending,
    onUpdateDefinition: mutateAsync,
    onUpdateDefinitionError: isError,
    onUpdateDefinitionSuccess: isSuccess,
  };
};

export const useImportDataFromBlob = () => {
  const client = useQueryClient();
  const { user } = useAuth();
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [importDataFromBlob.name],
    mutationFn: (variables: {
      guid: string;
      branchId: number | undefined;
      payload: MapProperty[];
    }) => {
      return importDataFromBlob.query(
        variables.guid,
        variables.branchId,
        variables.payload,
        user?.accessToken ?? undefined
      );
    },
    onSuccess: () => {
      const invalidateProductsSchema = [
        `${baseKey}.${getSchemaLibrary.name}`,
        'Product',
        user?.accessToken,
      ];

      void client.invalidateQueries({ queryKey: invalidateProductsSchema });
      void client.invalidateQueries();

     
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    importDataFromBlobPending: isPending,
    importDataFromBlobAsync: mutateAsync,
    importDataFromBlobError: isError,
    importDataFromBlobSuccess: isSuccess,
  };
};

export const useUpdateProductsBatch = () => {
  const client = useQueryClient();
  const { user } = useAuth();
  const toast = useToast();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [updateProductsBatch.name],
    mutationFn: (payload: UpdateProductBatch | undefined  ) => {
      return updateProductsBatch.query(
        payload,
        user?.accessToken ?? undefined
      );
    },
    onSuccess: () => {
      toast({
        description: 'Updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
     
    },
    onError: () => {
      toast({
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return {
    UpdateProductsBatchPending: isPending,
    UpdateProductsBatchAsync: mutateAsync,
    UpdateProductsBatchError: isError,
    UpdateProductsBatchSuccess: isSuccess,
  };
};

export const useUpdateProductOrderId = () => {
  const { user } = useAuth();

  const { mutateAsync, isPending, isError, isSuccess } = useMutation({
    mutationKey: [updateProductOrderId.name],
    mutationFn: ({productId, beforeOrderId}:{productId:string, beforeOrderId: string}) => {
      return updateProductOrderId.query(
        productId,
        beforeOrderId,
        user?.accessToken ?? undefined
      );
    }
  });

  return {
    isPending: isPending,
    mutateAsync: mutateAsync,
    isError: isError,
    isSuccess: isSuccess,
  };
};
