import { QueryFunctionContext } from '@tanstack/query-core';
import axios from 'axios';
import {
  ProductDataSchemaLibraryColumns,
  ProductPriceRecordsDto,
  SchemaFieldDefinition,
  SchemaFieldDefinitionDto,
} from '../../../@types/BackendViewModels';

export const getSchemaLibrary = {
  name: 'getSchemaLibrary',
  query: async (
    { queryKey }: QueryFunctionContext<[string, string | undefined]>,
    accessToken: string | undefined
  ) => {
    const [_, objectType] = queryKey;

    const url = `${process.env.REACT_APP_URL}/api/SchemaLibrary/${objectType}`;
    try {
      const res = await axios.get(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data as SchemaFieldDefinitionDto[];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getProductDataSchemaLibraryColumns = {
  name: 'getProductDataSchemaLibraryColumns',
  query: async (
    { queryKey }: QueryFunctionContext<[string]>,
    accessToken: string | undefined
  ) => {
    const [_] = queryKey;

    const url = `${process.env.REACT_APP_URL}/api/SchemaLibrary/ProductData/Columns`;
    try {
      const res = await axios.get<ProductDataSchemaLibraryColumns>(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
