import axios from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import {
  CreateSchemaFieldDefinitionDto,
  MapProperty,
  ProductAndSchemaDto,
  ProductDataWrapper,
  ProductPriceRecordsDto,
  SchemaFieldDefinitionDto,
  SegmentSummary,
  UpdateSchemaFieldDefinitionDto,
} from '../../../@types/BackendViewModels';
import { SchemaLibrary } from '../Pricing/pricing.models';
import { UpdateProductBatch } from './productDataManager.models';

export const updateProductDataManager = {
  name: 'updateProductDataManager',
  query: async (
    value: { [key: string]: string | object | null },
    branchId: number | undefined,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.patch<ProductDataWrapper>(
        `${process.env.REACT_APP_URL}/UpdateProductProperties`,
        value,
        {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            branchId: branchId,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

const buildItemsPropertiesUrl = (
  products_Group: string | undefined,
  columnsList: string[] | undefined,
  branchId: number
) => {
  const base_url = process.env.REACT_APP_URL;

  if (products_Group) {
    return `${base_url}/Items/${branchId}/${products_Group}?colNames=${columnsList?.toString()}`;
  } else {
    return `${base_url}/ItemsProperties/${branchId}`;
  }
};

export const getItemProperties = {
  name: 'getItemProperties',

  query: async ({
    queryKey,
  }: QueryFunctionContext<[string, string | undefined, number]>) => {
    const [_, accessToken, branchId] = queryKey;
    const base_url = process.env.REACT_APP_URL;
    try {
      const res = await axios.get(`${base_url}/Items/Properties/${branchId}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data as string[];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getItemsProperties = {
  name: 'getItemsProperties',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [
      string,
      string | undefined,
      string | undefined,
      string[] | undefined,
      number
    ]
  >) => {
    const [_, accessToken, products_Group, columnsList, branchId] = queryKey;
    const url = buildItemsPropertiesUrl(products_Group, columnsList, branchId);
    try {
      const res = await axios.get(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getGroupProperties = {
  name: 'getGroupProperties',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [string, string | undefined, number | undefined]
  >) => {
    const base_url = process.env.REACT_APP_URL;
    const [_, accessToken, branchId] = queryKey;

    const url = `${base_url}/GroupProperties/${branchId}`;

    try {
      const res = await axios.get(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

// Assuming getProductPriceRecordsDto.query is adjusted to accept an additional parameter for accessToken
export const getProductAndSchemaDto = {
  name: 'getProductAndSchemaDto',
  query: async (
    { queryKey }: QueryFunctionContext<[string, number | undefined]>,
    accessToken: string | undefined
  ) => {
    const [_, branchId] = queryKey;

    const url = `${process.env.REACT_APP_URL}/Items/${branchId}`;
    try {
      const res = await axios.get<ProductAndSchemaDto>(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const createSchemaLibraryField = {
  name: 'createSchemaLibraryField',
  query: async (
    definition: CreateSchemaFieldDefinitionDto,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/api/SchemaLibrary`,
        {
          ...definition,
          name: definition.label?.replace(/\s+/g, ''),
        },
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return res.data as SchemaFieldDefinitionDto;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const updateSchemaLibraryField = {
  name: 'updateSchemaLibraryField',
  query: async (
    definition: UpdateSchemaFieldDefinitionDto,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_URL}/api/SchemaLibrary`,
        definition,
        {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            // Origin: 'https://your-cors-origin.com',
          },

          withCredentials: true,
        }
      );
      return res.data as SchemaFieldDefinitionDto;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const importDataFromBlob = {
  name: 'importDataFromBlob',
  query: async (
    guid: string,
    branchId: number | undefined,
    payload: MapProperty[],
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/ImportDataFromBlob/${guid}`,
        payload,
        {
          params: {
            guid: guid,
            branchId: branchId,
          },
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const updateProductsBatch = {
  name: 'updateProductsBatch',
  query: async (
    payload: UpdateProductBatch|undefined,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/UpdateProducts/Batch`,
       payload,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return res.data ;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const updateProductOrderId = {
  name: 'updateProductOrderId',
  query: async (
    productId: string,
    beforeOrderId: string,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/Items/OrderId`,
        {productId, beforeOrderId},
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return res.data ;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};