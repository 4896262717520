import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import { Branch } from '../../hooks/useListManager';
import { tryFetch } from '../../utils/axios.error';
import { DraftHistoryResponse } from './lmBranches.models';

export const getMainBranchId = {
  name: 'getMainBranchId',

  query: async ({
    queryKey,
  }: QueryFunctionContext<[string, string | undefined]>): Promise<Branch> => {
    const [_, accessToken] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/LMBranches/GetMainBranch`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getAllBranches = {
  name: 'getAllBranches',

  query: async ({
    queryKey,
  }: QueryFunctionContext<[string, string | undefined]>): Promise<Branch[]> => {
    const [_, accessToken] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/LMBranches/GetAllBranches`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const createBranch = (() => {
  const name = 'createBranch';

  return {
    name,
    query: async (branchName: string, accessToken: string | undefined) => {
      const req = axios.post(
        `${process.env.REACT_APP_URL}/api/LMBranches/Branches`,
        { branchName, branchDescription: branchName, isMain: false },
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result as number;
    },
  };
})();

export const deleteBranch = {
  name: 'deleteBranch',
  query: async (accessToken: string | undefined, branchId: number) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_URL}/api/LMBranches/Branches/${branchId}`,

        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const getDraftHistory = (() => {
  const name = 'getDraftHistory';

  return {
    name,
    query: async ({
      queryKey,
    }: QueryFunctionContext<
      [string, string | undefined]
    >): Promise<DraftHistoryResponse> => {
      const [_, accessToken] = queryKey;

      const req = axios.get(
        `${process.env.REACT_APP_URL}/api/LMBranches/GetDraftHistory`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      const result = await tryFetch(req);

      return result as DraftHistoryResponse;
    },
  };
})();

export const publishBranch = (() => {
  const name = 'publishBranch';

  return {
    name,
    query: async (branchId: string | undefined, isPublished:boolean | undefined, accessToken: string | undefined) => {
      const req = axios.patch(
        `${process.env.REACT_APP_URL}/api/LMPublishedDrafts/PublishedDrafts`,
        {branchId:branchId, isPublished:isPublished},
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result as number;
    },
  };
})();

export const getPublishedDrafts = {
  name: 'getPublishedDrafts',

  query: async ({
    queryKey,
  }: QueryFunctionContext<[string, string | undefined]>): Promise<Branch[]> => {
    const [_, accessToken] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/LMPublishedDrafts/GetPublishedDrafts`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
