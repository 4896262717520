import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';

const PageTopElementBox = ({ children }: React.PropsWithChildren) => {
  const bg = useColorModeValue('white', 'gray.800');
  const border = useColorModeValue('gray.200', 'gray.700');

  return (
    <>
      <Flex
        as={'header'}
        p={2}
        bg={bg}
        borderBottomWidth="1px"
        borderBottomColor={border}
        borderRightWidth="1px"
        borderRightColor={border}
        position={'sticky'}
        left={0}
        top={0}
        width={'100%'}
        mt={0}
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        justifyContent={{ base: 'center', md: 'left' }}
        zIndex={5}
        alignItems={'center'}
      >
        {children}
      </Flex>
    </>
  );
};

export default PageTopElementBox;
