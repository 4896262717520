import React from 'react';
import {
  FiActivity,
  FiBook,
  FiCalendar,
  FiClipboard,
  FiClock,
  FiDollarSign,
  FiFolder,
  FiGlobe,
  FiHome,
  FiList,
  FiSettings,
} from 'react-icons/fi';

import { Badge, Progress } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  ListManagerProvider,
  useListManager,
} from '../../hooks/useListManager';
import { LinkItem } from '../../../@types/FrontendViewModels';
import { NavItem } from '../../../@types/BackendViewModels';
import PageTopElementBox from '../../components/misc/PageTopElementBox';
import BranchSelect from './components/BranchSelect';
import NavigationContainer from './components/NavigationContainer';
import Layout from '../../components/navigation/Layout';

interface PricingDashboardProps extends React.PropsWithChildren {
  showMenu: boolean;
}

const PricingDashboard = ({ showMenu, children }: PricingDashboardProps) => {
  return (
    <ListManagerProvider>
      <PricingDashboardInner showMenu={showMenu}>
        {children}
      </PricingDashboardInner>
    </ListManagerProvider>
  );
};

const PricingDashboardInner = ({
  showMenu,
  children,
}: PricingDashboardProps) => {
  const listManagerContext = useListManager();
  const params = useParams();

  const sidebar = React.useMemo<LinkItem[]>(() => {
    return [
      {
        name: 'Pricing Pipeline',
        icon: FiDollarSign,
        location: '/pricing',
      },
      {
        name: 'View',
        children: [
          {
            name: 'Current Pricing',
            icon: FiClipboard,
            location: listManagerContext.mainBranch?.id ? `/pricing/b/${listManagerContext.mainBranch.id}` : '/pricing',
            padding: '8',
          },
          {
            name: 'Schedule',
            icon: FiClock,
            location: '/pricing/schedule',
            padding: '8',
          },
          {
            name: 'Activity',
            icon: FiActivity,
            location: '',
            padding: '8',
            isDisabled: true,
          },
        ],
      },
      {
        name: 'Manage',
        children: [
          {
            name: 'Drafts',
            icon: FiBook,
            location: '/pricing/drafts',
            padding: '8',
          },
          {
            name: 'Strategies',
            icon: FiCalendar,
            location: '',
            padding: '8',
            isDisabled: true,
          },
          {
            name: 'Formulas',
            icon: FiClipboard,
            location: '/pricing/formulas',
            padding: '8',
          },
          {
            name: 'Segmentation',
            icon: FiList,
            location: '',
            padding: '8',
            isDisabled: true,
          },
          {
            name: 'Global Variables',
            icon: FiGlobe,
            location: '',
            padding: '8',
            isDisabled: true,
          },
        ],
      },
      {
        name: 'Data',
        children: [
          {
            name: 'Import/Export',
            icon: FiFolder,
            location: '/pricing/import',
            padding: '8',
          },
          {
            name: 'Properties',
            icon: FiSettings,
            location: '/pricing/property-settings',
            padding: '8',
          },
          {
            name: 'External Connections',
            icon: FiGlobe,
            location: '',
            padding: '8',
            isDisabled: true,
          },
        ],
      },
    ];
  }, [listManagerContext.mainBranch]);

  const items: NavItem[] = React.useMemo(
    () => [
      {
        label: 'Prices',
        to: `/pricing/b/${listManagerContext.branch?.id}`,
        icon: <FiHome strokeWidth={1} size={20} />,
        paths: [
          `/pricing/b/${listManagerContext.branch?.id}`,
          `/pricing`,
          `/pricing/b/${listManagerContext.branch?.id}/s/${params.segmentId}`,
          `/pricing/b/${listManagerContext.branch?.id}/s/${params.segmentId}/d/${params.detailsId}`,
        ],
      },
      {
        label: 'Product Data',
        to: `/pricing/b/${listManagerContext.branch?.id}/productDataManager`,
        icon: <FiActivity strokeWidth={1} size={20} />,
        paths: [
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager`,
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager/p/${params.products_Group}`,
          `/pricing/b/${listManagerContext.branch?.id}/productDataManager/all`,
        ],
      },
      {
        label: 'Compare',
        to: `/pricing/compare/b/${params.branchId}`,
        icon: <FiBook strokeWidth={1} size={20} />,
        paths: [`/pricing/compare/b/${params.branchId}`],
      },
    ],
    [params, listManagerContext.branch?.id]
  );

  const innerNav = React.useMemo(
    () => (
      <>
        <PageTopElementBox>
          <BranchSelect />
          {listManagerContext.branch &&
            listManagerContext.branch?.isPublished &&
            !listManagerContext.branch?.isMain &&
            !listManagerContext.branch?.isCompleted && (
              <Badge
                variant="outline"
                ml={2}
                borderRadius={3}
                colorScheme="green"
                pointerEvents={'none'}
              >
                Published
              </Badge>
            )}
        </PageTopElementBox>
        {showMenu && (
          <NavigationContainer
            items={items}
            disableCompare={!!listManagerContext.branch?.isMain}
          >
            <>
              {(listManagerContext.isNavigating ||
                listManagerContext.isLoading) && (
                <Progress size="xs" isIndeterminate />
              )}
            </>
          </NavigationContainer>
        )}
      </>
    ),
    [
      items,

      listManagerContext.branch,
      listManagerContext.isLoading,
      listManagerContext.isNavigating,
      showMenu,
    ]
  );

  return (
    <Layout sidebarItems={sidebar} topElement={innerNav}>
      {children}
    </Layout>
  );
};

export default PricingDashboard;
