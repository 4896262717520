import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import useAuth from './useAuth';
import axios from 'axios';
import {
  useLocation,
  useNavigate,
  generatePath,
  useParams,
  matchRoutes,
} from 'react-router-dom';
import {
  CreateLmSegmentDto,
  CustomFormulaDetails,
  CreateCustomFormulaDto,
  SegmentSummary,
  UpdateCustomFormulaDto,
  SegmentSummaryHierarchy,
  MapProperty,
  SchemaFieldDefinitionDto,
} from '../../@types/BackendViewModels';
import { useDisclosure } from '@chakra-ui/hooks';
import { useSteps } from '@chakra-ui/stepper';
import { v4 as uuidv4 } from 'uuid';
import {
  baseKey,
  getProductPriceRecordsDto,
  useGetCustomFormulas,
} from '../apis/Pricing';
import { useQueryClient } from '@tanstack/react-query';
import {
  DraftHistoryResponse,
  useCreateBranch,
  useDeleteBranch,
  useGetAllBranches,
  useGetDraftHistory,
  useGetMainBranch,
} from '../apis/LMBranches';

import {
  useGetItemProperties,
  useGetProductDataWrapperRecords,
  useImportDataFromBlob,
} from '../apis/ProductDataManager';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { useGetSchemaLibrary } from '../apis/SchemaLibrary/useSchemaLibrary.hooks';

export interface Branch {
  id: number;
  branchName: string;
  branchDescription: string;
  isMain: boolean;
  createdBy?: string;
  createdDate?: Date;
  isCompleted: boolean;
  isPublished?: boolean;
}

export interface ListManagerContextType {
  isLoading: boolean;
  isNavigating: boolean;
  mainBranch: Branch | undefined;
  branch: Branch | null;
  branches: Branch[] | undefined;
  activeBranches: Branch[] | undefined;
  segmentSummaries: SegmentSummary[];
  flatSummaryList: { id: number; name: string; depth: number }[];
  segmentSummaryHierarchys: SegmentSummaryHierarchy[];
  customFormulas: CustomFormulaDetails[];
  setCurrentBranch: (
    branchId: number,
    branchData?: Branch[] | undefined
  ) => void;
  createBranch: (branchName: string) => Promise<number | void>;
  deleteBranch: (branchId: number) => Promise<void | Branch[]>;
  getSegmentSummaries: () => Promise<SegmentSummary[] | void>;
  deleteSegment: (segmentId: number) => Promise<SegmentSummary[] | void>;
  deleteSegments: (segmentIds: number[]) => Promise<SegmentSummary[] | void>;
  updateSegmentReference: (
    segmentId: number,
    parentId: number | null | undefined,
    targetSegmentId: number | null
  ) => void;
  updateSegmentModifier: (
    segmentId: number,
    modifier: number | null | undefined
  ) => void;
  copySegment: (segmentId: number) => void;
  createSingleSegment: (
    createLmSegmentDto: CreateLmSegmentDto
  ) => Promise<number | void> | undefined;
  createFormula: (
    createFormulaDto: CreateCustomFormulaDto
  ) => Promise<number | void>;
  updateFormula: (
    updateFormulaDto: UpdateCustomFormulaDto
  ) => Promise<number | void>;
  deleteFormula: (id: number) => Promise<boolean>;
  jobId: string | null;
  getDraftHistory: DraftHistoryResponse;
  productFieldNames: string[];
  handleSubmit: ({
    guid,
    branchId,
  }: {
    guid: string;
    branchId: number | undefined;
  }) => void;
  importDataFromBlobPending: boolean;
  isSubmissionOpen: boolean;
  onSubmissionOpen: () => void;
  onSubmissionClose: () => void;
  uploading: boolean;
  counter: number;
  fileToBeUpload: File | null;
  beginingOfTheChunk: number;
  endOfTheChunk: number;

  fileSize: number;
  chunkCount: number;
  fileUp: boolean;
  previewData: string[];
  columnData: string[];
  guid: string;
  colName: string[];
  upStatus: number;
  selectedFiles: FileList | null;
  wrongFile: boolean;
  activeStep: number;

  setUploading: Dispatch<SetStateAction<boolean>>;
  setCounter: Dispatch<SetStateAction<number>>;
  setFileToBeUpload: Dispatch<SetStateAction<File | null>>;
  setBeginingOfTheChunk: Dispatch<SetStateAction<number>>;
  setEndOfTheChunk: Dispatch<SetStateAction<number>>;
  setJobId: Dispatch<SetStateAction<string | null>>;
  setFileSize: Dispatch<SetStateAction<number>>;
  setChunkCount: Dispatch<SetStateAction<number>>;
  setFileUp: Dispatch<SetStateAction<boolean>>;
  setPreviewData: Dispatch<SetStateAction<string[]>>;
  setColumnData: Dispatch<SetStateAction<string[]>>;
  setGuid: Dispatch<SetStateAction<string>>;
  setColName: Dispatch<SetStateAction<string[]>>;
  setUpStatus: Dispatch<SetStateAction<number>>;
  setSelectedFiles: Dispatch<SetStateAction<FileList | null>>;
  setWrongFile: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  previewTable: () => void;
  schemaDetails: () => void;
  getFileContext: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLInputElement>) => void;
  handleDragLeave: (e: React.DragEvent<HTMLInputElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLInputElement>) => void;
  uploadProducts: () => void;
  checkCol: (e: boolean, item: string) => void;
  isPreventEditOpen: boolean;
  onPreventEditOpen: () => void;
  onPreventEditClose: () => void;
  isCreateBranchOpen: boolean;
  onCreateBranchOpen: () => void;
  onCreateBranchClose: () => void;
  navigateToNewBranch: (value: number) => void;
  deletingBranch: boolean;
  SchemaLibraryData: SchemaFieldDefinitionDto[] | undefined;
  SchemaLibraryLoading: boolean;
  mapProperty: MapProperty[];
  setMapProperty: Dispatch<SetStateAction<MapProperty[]>>;
  refetchBranch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Branch[], Error>>;
  itemProperties: string[] | undefined;
  additionalSegmentIdsToPrice: number[];
  setAdditionalSegmentIdsToPrice: Dispatch<SetStateAction<number[]>>;
}

const ListManagerContext = createContext<ListManagerContextType | undefined>(
  undefined
);

const useListManager = (): ListManagerContextType => {
  const context = useContext(ListManagerContext);

  if (!context) {
    throw new Error('useListManager must be used within a ListManagerProvider');
  }

  return { ...context };
};

const steps = [
  { title: 'Upload File', description: 'Upload CSV file' },
  { title: 'Preview File', description: 'We can preview file upload' },
  { title: 'Review', description: 'Review and submit' },
];

const chunkSize = 1048576 * 1; //its 3MB, increase the number measure in mb

//Point of this class is to create a new branch when changes are made while pointing to main branch.
const ListManagerProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const client = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNavigating, setIsNavigating] = useState<boolean>(true);
  const [branch, setBranch] = useState<Branch | null>(null);
  const [mainBranch, setMainBranch] = useState<Branch | undefined>(undefined);

  const [segmentSummaries, setSegmentSummaries] = useState<SegmentSummary[]>(
    []
  );
  const [segmentSummaryHierarchys, setSegmentSummaryHierarchys] = useState<
    SegmentSummaryHierarchy[]
  >([]);

  const [flatSummaryList, setFlatSummaryList] = useState<
    { id: number; name: string; depth: number }[]
  >([]);

  // Import Cost Data State
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [uploading, setUploading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState<File | null>(null);
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);

  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [fileUp, setFileUp] = useState(false);
  const [previewData, setPreviewData] = useState<string[]>([]);
  const [columnData, setColumnData] = useState<string[]>([]);
  const [guid, setGuid] = useState('');
  const [colName, setColName] = useState<string[]>([]);
  const [upStatus, setUpStatus] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [wrongFile, setWrongFile] = useState(false);
  const [jobId, setJobId] = useState<string | null>(null);
  const [productFieldNames, setProductFieldNames] = useState<string[]>([]);
  const [mapProperty, setMapProperty] = useState<MapProperty[]>([]);
  const [activeBranches, setActiveBranches] = useState<Branch[]>([]);

  const [additionalSegmentIdsToPrice, setAdditionalSegmentIdsToPrice] =
    React.useState<number[]>([]);

  const { mainBranchId } = useGetMainBranch();

  const { customFormulas, customFormulasRefetch } = useGetCustomFormulas(
    params.branchId ?? mainBranchId?.id?.toString(),
    user?.accessToken ?? undefined
  );

  const { branches, refetchBranch } = useGetAllBranches(
    user?.accessToken ?? undefined
  );
  const { createBranch, creatingBranch } = useCreateBranch();
  const { deleteBranch, deletingBranch } = useDeleteBranch();
  const { getDraftHistory } = useGetDraftHistory();

  // END IMPORT COST DATA STATE

  useEffect(() => {
    const mainBranch = branches?.find(x => x.isMain);
    setMainBranch(mainBranch);
  }, [branches]);

  useEffect(() => {
    if (branches) {
      setActiveBranches(branches.filter(x => !x.isCompleted));
    }
  }, [branches]);

  const flattenSegmentSummaries = React.useCallback(
    (
      segment: SegmentSummary,
      parentHierarchy: string[] = []
    ): SegmentSummaryHierarchy[] => {
      const hierarchy = [...parentHierarchy, segment.segmentName];
      const flatSegment: SegmentSummaryHierarchy = {
        id: segment.id,
        segmentName: segment.segmentName,
        segmentDescription: segment.segmentDescription,
        priceCalculationDescription: segment.priceCalculationDescription,
        customMarginFormula: segment.customMarginFormula,
        outputPriceType: segment.outputPriceType,
        priceCalculationType: segment.priceCalculationType,
        priceCalculationModifier: segment.priceCalculationModifier,
        itemCostFieldName: segment.itemCostFieldName,
        groupOverrideCount: segment.groupOverrideCount,
        itemOverrideCount: segment.itemOverrideCount,
        anchorCount: segment.anchorCount,
        childrenCount: segment.children.length,
        hierarchy: hierarchy,
        children: [],
        externalSegmentId: segment.externalSegmentId,
      };

      // Start with the current segment
      let flatList: SegmentSummaryHierarchy[] = [flatSegment];

      // Recursively flatten each child and concatenate to the list
      segment.children.forEach(child => {
        flatList = flatList.concat(flattenSegmentSummaries(child, hierarchy));
      });

      return flatList;
    },
    []
  );

  useEffect(() => {
    const hierarchys: SegmentSummaryHierarchy[] = [];
    segmentSummaries.map(x => {
      const flattenedSegmentHierarchys = flattenSegmentSummaries(x);
      hierarchys.push(...flattenedSegmentHierarchys);
    });
    setSegmentSummaryHierarchys(hierarchys);
  }, [segmentSummaries, flattenSegmentSummaries]);

  const {
    isOpen: isSubmissionOpen,
    onOpen: onSubmissionOpen,
    onClose: onSubmissionClose,
  } = useDisclosure();

  const {
    isOpen: isPreventEditOpen,
    onOpen: onPreventEditOpen,
    onClose: onPreventEditClose,
  } = useDisclosure();

  const {
    isOpen: isCreateBranchOpen,
    onOpen: onCreateBranchOpen,
    onClose: onCreateBranchClose,
  } = useDisclosure();

  const routes = React.useMemo(
    () => [
      {
        path: '/pricing/',
        requiresNavigate: false,
        requiresDependent: false,
      },
      {
        path: '/pricing/b/:branchId',
        requiresNavigate: false,
        requiresDependent: false,
      },
      {
        path: '/pricing/b/:branchId/s/:segmentId',
        requiresNavigate: true,
        requiresDependent: true,
      },
      {
        path: '/pricing/b/:branchId/s/:segmentId/d/:detailsId',
        requiresNavigate: true,
        requiresDependent: true,
      },
      {
        path: '/pricing/b/:branchId/productDataManager',
        requiresNavigate: true,
        requiresDependent: false,
      },
      {
        path: '/pricing/b/:branchId/productDataManager/p/:products_Group',
        requiresNavigate: true,
        requiresDependent: false,
      },
      {
        path: '/pricing/schedule/b/:branchId',
        requiresNavigate: true,
        requiresDependent: false,
      },
    ],
    []
  );

  const { SchemaLibraryData, SchemaLibraryLoading } = useGetSchemaLibrary(
    'Product',
    user?.accessToken ?? undefined
  );

  // IMPORT COST DATA FUNCTIONALITY

  const previewTable = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/GetBlobItemProperties/${guid}`, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${user?.accessToken}` },
      })
      .then(res => {
        if (res.data != '') {
          setPreviewData(res.data);

          const columns = Object.keys(res.data[0]);

          setColumnData(columns);
          setColName([...colName, columns[0]]);
        }
      });
    setActiveStep(activeStep + 1);
  }, [
    activeStep,
    colName,
    guid,
    setActiveStep,
    setColName,
    setColumnData,
    setPreviewData,
    user?.accessToken,
  ]);

  const uploadCompleted = useCallback(async () => {
    const complete = await axios.post(
      `${process.env.REACT_APP_URL}/UploadComplete`,
      {},
      {
        params: {
          fileGuid: guid,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
      }
    );
    if (complete.data) {
      setUploading(false);
      previewTable();
      setActiveStep(activeStep + 1);
    }

    const data = complete.data.value;
    setUpStatus(200);
  }, [
    guid,
    user?.accessToken,
    setUpStatus,
    setUploading,
    previewTable,
    setActiveStep,
    activeStep,
  ]);

  const uploadChunk = useCallback(
    async (chunk: FormData) => {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/UploadChunks`,
        chunk,
        {
          params: {
            id: counter,
            fileName: chunk.get('fileName'),
            fileGuid: guid,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      const data = response.data;

      if (data === 'Success') {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        setFileUp(true);

        if (counter + 1 === chunkCount) {
          if (selectedFiles !== undefined) {
            await uploadCompleted();
          }

          setBeginingOfTheChunk(0);
          setEndOfTheChunk(chunkSize);
        } else {
          // nothing
        }
      } else {
        console.log('Error Occurred:', data.errorMessage);
      }
    },
    [
      counter,
      chunkCount,
      endOfTheChunk,
      guid,
      selectedFiles,
      setBeginingOfTheChunk,
      setEndOfTheChunk,
      setFileUp,
      uploadCompleted,
      user?.accessToken,
    ]
  );

  const fileUpload = useCallback(
    (counter: number) => {
      setCounter(counter + 1);
      if (
        counter <= chunkCount &&
        fileToBeUpload != undefined &&
        selectedFiles != undefined
      ) {
        setFileUp(false);

        const chunk = fileToBeUpload?.slice(beginingOfTheChunk, endOfTheChunk);
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append(
          'fileName',
          selectedFiles[0].name.split('.').slice(0, -1).join('.')
        );

        uploadChunk(formData);
      }
    },
    [
      setCounter,
      chunkCount,
      fileToBeUpload,
      selectedFiles,
      setFileUp,
      beginingOfTheChunk,
      endOfTheChunk,
      uploadChunk,
    ]
  );

  useEffect(() => {
    if (fileSize > 0 && counter < chunkCount && fileUp) {
      fileUpload(counter);
    }
  }, [fileSize, counter, chunkCount, fileUp, fileUpload]);

  const getFileContext = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCounter(0);
    setFileUp(true);
    setWrongFile(false);
    const files = event.target.files;

    if (files && files.length > 0) {
      setSelectedFiles(files);
    } else {
      setSelectedFiles(null);
    }
  };

  const uploadProducts = () => {
    if (null != selectedFiles) {
      setUploading(true);
      const _file = selectedFiles[0];
      if (!_file) return;
      setFileSize(_file.size);
      const _totalCount =
        _file.size % chunkSize == 0
          ? _file.size / chunkSize
          : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
      setChunkCount(_totalCount);
      setGuid(uuidv4());
      setFileToBeUpload(_file);
    }
  };

  const schemaDetails = () => {
    setActiveStep(activeStep + 1);
  };

  const checkCol = (e: boolean, item: string) => {
    if (e == true) {
      setColName([...colName, item]);
    } else {
      const index = colName.indexOf(item);
      if (index !== -1) {
        colName.splice(index, 1);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputElement = e.target as HTMLInputElement;
    const uploadContainer = inputElement.closest(
      '.upload-container'
    ) as HTMLElement | null;

    if (uploadContainer && !branch?.isMain) {
      uploadContainer.classList.add('active');
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputElement = e.target as HTMLInputElement;
    const uploadContainer = inputElement.closest(
      '.upload-container'
    ) as HTMLElement | null;

    if (uploadContainer) {
      uploadContainer.classList.remove('active');
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputElement = e.target as HTMLInputElement;
    const uploadContainer = inputElement.closest(
      '.upload-container'
    ) as HTMLElement | null;

    if (uploadContainer) {
      uploadContainer.classList.remove('active');
    }

    const files = e.dataTransfer.files;

    if (files && !branch?.isMain) {
      // Check if any of the dropped files has a CSV format
      const hasCSV = Array.from(files).some(file => file.type === 'text/csv');

      if (hasCSV) {
        setCounter(0);
        setFileUp(true);

        setWrongFile(false);
        setSelectedFiles(files);
      } else {
        setWrongFile(true);
        setTimeout(() => {
          setWrongFile(false);
        }, 3000);
      }
    }
  };
  // END IMPORT COST DATA FUNCTIONALITY

  const navigateToNewBranch = useCallback(
    (branchId: number) => {
      setIsLoading(true);
      setIsNavigating(true);
      // @ts-ignore
      const [{ route }] = matchRoutes(routes, location);
      if (route.requiresDependent) {
        axios
          .get<number>(
            `${process.env.REACT_APP_URL}/GetDependentSegmentId/${branchId}/${params.segmentId}`,
            {
              responseType: 'json',
              headers: { Authorization: `Bearer ${user?.accessToken}` },
            }
          )
          .then(res => {
            if (!res) {
              navigate(`/pricing/b/${branchId}`);
            } else {
              const newPath = generatePath(route.path, {
                branchId: branchId.toString() ?? null,
                segmentId: res.data.toString(),
                detailsId: params.detailsId ?? null,
              });

              navigate(newPath);
            }
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
          });
      } else {
        navigate(`/pricing/b/${branchId}`);
        setIsLoading(false);
      }
    },
    [location, params, navigate, routes, user?.accessToken]
  );

  useEffect(() => {
    // if branch exist in context and matches to branches
    const branchExist = branches?.find(x => x.id === branch?.id);

    if (Number(params.branchId) === branch?.id || branchExist) {
      setIsNavigating(false);
      return;
    }

    //check for branch in branches and set it
    //if branch not in branches then get branches again to set
    if (branches?.some(x => x.id === Number(params.branchId))) {
      const match = branches?.filter(x => x.id === Number(params.branchId));
      if (match.length > 0) {
        setBranch(match[0]);
        setIsNavigating(false);
      } else {
        navigate(`/pricing`);
      }
    } else {
      //requested branch not found in branch list. get branches again
      if (branches) {
        if (!params.branchId) {
          if (mainBranchId) {
            setBranch(mainBranchId);
            setIsNavigating(false);
          }
        } else {
          if (branches) {
            const match = branches?.filter(
              x => x.id === Number(params.branchId)
            );
            if (match.length > 0) {
              setBranch(match[0]);
              setIsNavigating(false);
            } else {
              navigate(`/pricing`);
            }
          }
        }
      }
    }
  }, [branch?.id, branches, mainBranchId, navigate, params.branchId]);

  useEffect(() => {
    if (params.branchId && Number(params.branchId) !== branch?.id && branches) {
      const matchedBranch = branches?.find(
        branch => branch.id === Number(params.branchId)
      );

      if (matchedBranch) {
        setBranch(matchedBranch);
      } else {
        navigate(`/pricing`);
      }
    }
  }, [params.branchId, branch, branches, navigate]);

  const { refetch: RefetchProductData } = useGetProductDataWrapperRecords(
    mainBranchId?.id,
    user?.accessToken ?? undefined
  );

  const setCurrentBranch = (
    branchId: number,
    branchData?: Branch[] | undefined
  ) => {
    const match = branchData
      ? branchData?.filter(x => x.id === branchId)
      : branches?.filter(x => x.id === branchId);

    if (match && match.length > 0) {
      // Remove existing prices
      setAdditionalSegmentIdsToPrice([]);

      setBranch(match[0]);
      getSegmentSummaries();
      navigateToNewBranch(branchId);
      if (match[0].isMain) {
        RefetchProductData();
      }
    }
  };

  const setFlatSummariesList = useCallback(
    (segments: SegmentSummary[]) => {
      const parentSegments = [] as {
        id: number;
        name: string;
        depth: number;
      }[];

      const getFlatSummaryList = (
        segmentSummariesList: SegmentSummary[],
        depth: number
      ) => {
        segmentSummariesList.map(x => {
          parentSegments.push({ id: x.id, name: x.segmentName, depth: depth });
          getFlatSummaryList(x.children, depth + 1);
        });
      };
      getFlatSummaryList(segments, 0);
      setFlatSummaryList(parentSegments);
    },
    [setFlatSummaryList]
  );

  // LATER USE - NEED TO CONFIGURE WITH OTHER APIS WITH QUERY

  // // Summaries list API
  // const { SegmentSummaries, SegmentSummariesLoading } = useGetSegmentSummaries(
  //   params.branchId ?? mainBranchId?.id?.toString(),
  //   user?.accessToken ?? undefined
  // );

  // // Update summaries when get Summaries list
  // useEffect(() => {
  //   if (SegmentSummaries) {
  //     setSegmentSummaries(SegmentSummaries);
  //     setFlatSummariesList(SegmentSummaries);
  //     setIsLoading(false);
  //   }
  //   if (SegmentSummariesLoading) {
  //     setIsLoading(true);
  //   }
  // }, [SegmentSummaries, setFlatSummariesList, SegmentSummariesLoading]);

  const getSegmentSummaries = useCallback(() => {
    setIsLoading(true);
    return axios
      .get<SegmentSummary[]>(
        `${process.env.REACT_APP_URL}/Segments/List/${branch?.id}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${user?.accessToken}` },
        }
      )
      .then(res => {
        setSegmentSummaries(res.data);
        setFlatSummariesList(res.data);
        setIsLoading(false);
        return res.data;
      })
      .catch(err => {
        console.warn(err.data);
        setIsLoading(false);
        return;
      });
  }, [user?.accessToken, branch?.id, setFlatSummariesList]);

  useEffect(() => {
    if (branch) {
      setIsLoading(true);
      getSegmentSummaries().then(() => setIsLoading(false));
    }
  }, [branch, getSegmentSummaries]);

  const deleteSegment = useCallback(
    (segmentId: number) => {
      return axios
        .delete<number>(`${process.env.REACT_APP_URL}/Segments/${segmentId}`, {
          responseType: 'json',
          headers: { Authorization: `Bearer ${user?.accessToken}` },
        })
        .then(res => {
          return getSegmentSummaries();
        })
        .catch(err => {
          console.warn(err);
          return;
        });
    },
    [getSegmentSummaries, user?.accessToken]
  );

  const deleteSegments = useCallback(
    (segmentIds: number[]) => {
      console.warn(segmentIds);
      return axios
        .post<number>(
          `${process.env.REACT_APP_URL}/Segments/Delete/${branch?.id}`,
          segmentIds,
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          return getSegmentSummaries();
        })
        .catch(err => {
          console.warn(err);
          return;
        });
    },
    [getSegmentSummaries, user?.accessToken, branch]
  );

  const createSingleSegment = useCallback(
    (createLmSegmentDto: CreateLmSegmentDto) => {
      if (branch?.isMain) {
        const currentDate = new Date();

        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months are zero-based
        const year = currentDate.getFullYear();

        const dateStr = month + '/' + day + '/' + year;

        createBranch(`${user?.displayName} ${dateStr}`, {
          onSuccess: branchId => {
            if (branchId) {
              axios
                .get<number>(
                  `${process.env.REACT_APP_URL}/GetDependentSegmentId/${branchId}/${createLmSegmentDto.parentId}`,
                  {
                    responseType: 'json',
                    headers: { Authorization: `Bearer ${user?.accessToken}` },
                  }
                )
                .then(parentId => {
                  createLmSegmentDto.branchId = branchId;
                  createLmSegmentDto.parentId = parentId.data;
                  return axios
                    .post<number>(
                      `${process.env.REACT_APP_URL}/Segments`,
                      createLmSegmentDto,
                      {
                        responseType: 'json',
                        headers: {
                          Authorization: `Bearer ${user?.accessToken}`,
                        },
                      }
                    )
                    .then(res => {
                      getSegmentSummaries();
                      return res.data;
                    })
                    .catch(err => {
                      console.warn(err);
                    });
                });
            }
          },
        });
      } else {
        return axios
          .post<number>(
            `${process.env.REACT_APP_URL}/Segments`,
            createLmSegmentDto,
            {
              responseType: 'json',
              headers: { Authorization: `Bearer ${user?.accessToken}` },
            }
          )
          .then(res => {
            getSegmentSummaries();
            return res.data;
          })
          .catch(err => {
            console.warn(err);
          });
      }
    },
    [
      user?.accessToken,
      user?.displayName,
      branch,
      createBranch,
      getSegmentSummaries,
    ]
  );

  const createFormula = useCallback(
    (customFormula: CreateCustomFormulaDto) => {
      return axios
        .post<CustomFormulaDetails>(
          `${process.env.REACT_APP_URL}/CustomFormula`,
          customFormula,
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          customFormulasRefetch();
          return res.data.id;
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [customFormulasRefetch, user?.accessToken]
  );

  const updateFormula = useCallback(
    (customFormula: UpdateCustomFormulaDto) => {
      return axios
        .patch<CustomFormulaDetails>(
          `${process.env.REACT_APP_URL}/CustomFormula`,
          customFormula,
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          customFormulasRefetch();
          return res.data.id;
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [customFormulasRefetch, user?.accessToken]
  );

  const deleteFormula = useCallback(
    (id: number) => {
      return axios
        .delete(`${process.env.REACT_APP_URL}/CustomFormula/${id}`, {
          responseType: 'json',
          headers: { Authorization: `Bearer ${user?.accessToken}` },
        })
        .then(res => {
          // Update the state with the new array
          customFormulasRefetch();
          return true;
        })
        .catch(err => {
          console.warn(err);
          return false;
        });
    },
    [user?.accessToken, customFormulasRefetch]
  );

  const { importDataFromBlobAsync, importDataFromBlobPending } =
    useImportDataFromBlob();

  const handleSubmit = async ({
    guid,
    branchId,
  }: {
    guid: string;
    branchId: number | undefined;
  }) => {
    importDataFromBlobAsync(
      {
        guid: guid,
        branchId: branchId,
        payload: mapProperty,
      },
      {
        onSuccess: res => {
          if (res) {
            onSubmissionClose();
            setPreviewData([]);
            setColumnData([]);
            setUpStatus(0);
            setColName([]);
            setActiveStep(0);
            setSelectedFiles(null);
            setGuid('');
            setFileToBeUpload(null);
            setUploading(false);
            setFileSize(0);
            setChunkCount(0);
            setJobId(res);
            setMapProperty([]);
            getSegmentSummaries();
            setFileUp(false);
          }
        },
      }
    );
  };

  const updateSegmentReference = useCallback(
    (
      segmentId: number,
      parentId: number | null | undefined,
      targetSegmentId: number | null
    ) => {
      return axios
        .patch(
          `${process.env.REACT_APP_URL}/Segments/Reference`,
          {
            id: segmentId,
            parentId: parentId,
            targetSegmentId: targetSegmentId,
          },
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          client.invalidateQueries({
            queryKey: [
              `${baseKey}.${getProductPriceRecordsDto.name}`,
              segmentId.toString(),
            ],
          });
          return getSegmentSummaries();
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [getSegmentSummaries, user?.accessToken, client]
  );

  const updateSegmentModifier = useCallback(
    (segmentId: number, modifier: number | null | undefined) => {
      if (!modifier) {
        return;
      }
      return axios
        .patch<number>(
          `${process.env.REACT_APP_URL}/Segments/Modifier`,
          { id: segmentId, modifier: modifier },
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          client.invalidateQueries({
            queryKey: [
              `${baseKey}.${getProductPriceRecordsDto.name}`,
              segmentId.toString(),
            ],
          });
          return getSegmentSummaries();
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [getSegmentSummaries, user?.accessToken, client]
  );

  const copySegment = useCallback(
    (segmentId: number) => {
      return axios
        .post(
          `${process.env.REACT_APP_URL}/Segments/Copy`,
          { id: segmentId },
          {
            responseType: 'json',
            headers: { Authorization: `Bearer ${user?.accessToken}` },
          }
        )
        .then(res => {
          return getSegmentSummaries();
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [getSegmentSummaries, user?.accessToken]
  );

  //TODO this shouldnt be sending 0 branch id
  const { data: itemProperties } = useGetItemProperties(
    user?.accessToken ?? undefined,
    branch?.id ?? 0
  );

  useEffect(() => {
    if (itemProperties) {
      setProductFieldNames(itemProperties);
    }
  }, [itemProperties]);

  return (
    <ListManagerContext.Provider
      value={{
        isNavigating,
        isLoading,
        mainBranch,
        branch,
        branches,
        activeBranches,
        getDraftHistory,
        segmentSummaries,
        flatSummaryList,
        segmentSummaryHierarchys,
        setCurrentBranch,
        createBranch,
        deleteBranch,
        deleteSegment,
        deleteSegments,
        updateSegmentReference,
        updateSegmentModifier,
        copySegment,
        getSegmentSummaries,
        createSingleSegment,
        createFormula,
        updateFormula,
        deleteFormula,
        customFormulas,
        jobId,
        handleSubmit,
        importDataFromBlobPending,
        isSubmissionOpen,
        onSubmissionOpen,
        onSubmissionClose,
        uploading,
        counter,
        fileToBeUpload,
        beginingOfTheChunk,
        endOfTheChunk,

        fileSize,
        chunkCount,
        fileUp,
        previewData,
        columnData,
        guid,
        colName,
        upStatus,
        selectedFiles,
        wrongFile,
        activeStep,
        setUploading,
        setCounter,
        setFileToBeUpload,
        setBeginingOfTheChunk,
        setEndOfTheChunk,

        setFileSize,
        setChunkCount,
        setFileUp,
        setPreviewData,
        setColumnData,
        setGuid,
        setColName,
        setUpStatus,
        setSelectedFiles,
        setWrongFile,
        setActiveStep,
        previewTable,
        schemaDetails,
        getFileContext,
        handleDragOver,
        handleDragLeave,
        handleDrop,
        uploadProducts,
        checkCol,
        setJobId,
        productFieldNames,
        isPreventEditOpen,
        onPreventEditOpen,
        onPreventEditClose,
        isCreateBranchOpen,
        onCreateBranchOpen,
        onCreateBranchClose,
        navigateToNewBranch,
        deletingBranch,
        SchemaLibraryData,
        SchemaLibraryLoading,
        mapProperty,
        setMapProperty,
        refetchBranch,
        itemProperties,
        additionalSegmentIdsToPrice,
        setAdditionalSegmentIdsToPrice,
      }}
    >
      {children}
    </ListManagerContext.Provider>
  );
};

export { ListManagerProvider, useListManager };
