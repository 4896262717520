import axios from 'axios';
import { QueryFunctionContext } from '@tanstack/query-core';
import {
  CompareProductPriceRecords,
  GlobalVariables,
  ProductGroupDetailsItem,
  ProductPriceRecord,
  ProductPriceRecordsDto,
} from '../../../@types/BackendViewModels';
import { ItemsNames, PriceCalculationType, ProductAnchorRequest } from './pricing.models';
import { tryFetch } from '../../utils/axios.error';

export const createCustomItemProperties = {
  name: 'createCustomItemProperties',
  query: async (
    segmentId: string,
    itemPropertyColumnNames: string[] | undefined,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/CustomItemProperties`,
        { segmentId, itemPropertyColumnNames },
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const getAllPricingForSegment = {
  name: 'getAllPricingForSegment',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [string, string | undefined, string | undefined]
  >) => {
    const [_, segmentId, accessToken] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/Segments/Pricing/${segmentId}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getProductPriceRecord = {
  name: 'getProductPriceRecord',
  query: async (
    {
      queryKey,
    }: QueryFunctionContext<[string, string | undefined, string | undefined]>,
    accessToken: string | undefined
  ) => {
    const [_, segmentId, itemId] = queryKey;

    const url = `${process.env.REACT_APP_URL}/Segments/Pricing/${segmentId}/${itemId}`;
    try {
      const res = await axios.get<ProductPriceRecord>(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

// Assuming getProductPriceRecordsDto.query is adjusted to accept an additional parameter for accessToken
export const getProductPriceRecordsDto = {
  name: 'getProductPriceRecordsDto',
  query: async (
    { queryKey }: QueryFunctionContext<[string, string | undefined]>,
    accessToken: string | undefined
  ) => {
    const [_, segmentId] = queryKey;

    const url = `${process.env.REACT_APP_URL}/Segments/Pricing/${segmentId}`;
    try {
      const res = await axios.get<ProductPriceRecordsDto>(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getPriceListManagerData = {
  name: 'getPriceListManagerData',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [string, string | undefined, string | undefined, string | undefined]
  >) => {
    const [_, segmentId, detailsId, accessToken] = queryKey;

    const url =
      detailsId !== null && detailsId !== undefined
        ? `${process.env.REACT_APP_URL}/Segments/Pricing/${segmentId}/${detailsId}`
        : `${process.env.REACT_APP_URL}/Segments/Pricing/${segmentId}/All`;
    try {
      const res = await axios.get<ProductPriceRecordsDto>(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getCustomItemProperties = {
  name: 'getCustomItemProperties',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [string, string | undefined, string | undefined]
  >) => {
    const [_, segmentId, accessToken] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/GetCustomItemProperties/${segmentId}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getGetGlobalVariables = {
  name: 'getGetGlobalVariables',

  query: async ({
    queryKey,
  }: QueryFunctionContext<
    [string, string | undefined, string | undefined]
  >): Promise<GlobalVariables[]> => {
    const [_, accessToken, branchId] = queryKey;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/GetGlobalVariables/${branchId}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const createGlobalVariables = {
  name: 'createGlobalVariables',
  query: async (
    branchId: number,
    variableName: string | undefined,
    variableValue: number,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/GlobalVariables`,
        { branchId, variableName, variableValue },
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const updateGlobalVariables = {
  name: 'updateGlobalVariables',
  query: async (
    branchId: string | undefined,
    variableName: string | undefined,
    variableValue: number,
    accessToken: string | undefined,
    id: number
  ) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/GlobalVariables`,
        { branchId, variableName, variableValue, id },
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const deleteGlobalVariables = {
  name: 'deleteGlobalVariables',
  query: async (accessToken: string | undefined, id: number) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_URL}/GlobalVariables/${id}`,

        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const getCustomFormulas = {
  name: 'getCustomFormulas',

  query: async ({
    queryKey,
  }: QueryFunctionContext<[string, string | undefined, string | null]>) => {
    const [_, branchId, accessToken] = queryKey;

    const base_url = process.env.REACT_APP_URL;
    const url = `${base_url}/GetCustomFormulas/${branchId}`;

    try {
      const res = await axios.get(url, {
        responseType: 'json',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const addPriceCalculation = {
  name: 'addPriceCalculation',
  query: async (
    accessToken: string | undefined,
    itemsToAdd: PriceCalculationType[]
  ) => {
    try {
      const res = await axios.post<ProductGroupDetailsItem>(
        `${process.env.REACT_APP_URL}/ItemOverrides/Batch`,
        itemsToAdd,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const updatePriceCalculation = {
  name: 'updatePriceCalculation',
  query: async (
    accessToken: string | undefined,
    itemsToUpdate: PriceCalculationType[]
  ) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/ItemOverrides/Batch`,
        itemsToUpdate,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const upsertItemException = {
  name: 'upsertItemException',
  query: async (
    accessToken: string | undefined,
    exception: PriceCalculationType
  ) => {
    try {
      const res = await axios.post<ProductGroupDetailsItem>(
        `${process.env.REACT_APP_URL}/ItemOverrides/Upsert`,
        exception,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      return error;
    }
  },
};

export const getItemsGroups = (() => {
  const name = 'getItemsGroups';

  return {
    name,
    query: async ({
      queryKey,
    }: QueryFunctionContext<
      [string, string | undefined, string | undefined]
    >) => {
      const [_, accessToken, branchId] = queryKey;

      const req = axios.get(
        `${process.env.REACT_APP_URL}/Items/Groups/${branchId}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result as string[];
    },
  };
})();

export const getItemsNames = (() => {
  const name = 'getItemsNames';

  return {
    name,
    query: async ({
      queryKey,
    }: QueryFunctionContext<
      [string, string | undefined, string | undefined]
    >) => {
      const [_, accessToken, branchId] = queryKey;

      const req = axios.get(
        `${process.env.REACT_APP_URL}/ItemsNames/${branchId}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result as ItemsNames[];
    },
  };
})();

export const getFilteredItemPricing = (() => {
  const name = 'getFilteredItemPricing';

  return {
    name,
    query: async (
      accessToken: string | undefined,
      branchId: string | undefined,
      items: string[]
    ) => {
      const req = axios.get(
        `${
          process.env.REACT_APP_URL
        }/GetFilteredItemPricing/${branchId}?itemIds=${items.join(
          '&itemIds='
        )}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      if (result) {
        return result as ProductPriceRecord[];
      }

      return result as [];
    },
  };
})();

export const getFilteredGroupPricing = (() => {
  const name = 'getFilteredGroupPricing';

  return {
    name,
    query: async (
      accessToken: string | undefined,
      branchId: string | undefined,
      groups: string[]
    ) => {
      const req = axios.get(
        `${
          process.env.REACT_APP_URL
        }/GetFilteredGroupPricing/${branchId}?groupIds=${groups.join(
          '&groupIds='
        )}`,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      if (result) {
        return result;
      }

      return [];
    },
  };
})();

export const changeProductItemPrice = {
  name: 'changeProductItemPrice',
  query: async (
    payload: PriceCalculationType,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/ItemOverrides/Upsert`,
        payload,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      return res.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};

export const changePricesByOverrideDetails = (() => {
  const name = 'changePricesByOverrideDetails';

  return {
    name,
    query: async (
      payload: {
        priceCalculationModifier: number;
        priceCalculationType: string;
        itemIds: string[];
      },
      segmentId: number | undefined,
      accessToken: string | undefined
    ) => {
      const req = axios.post(
        `${process.env.REACT_APP_URL}/api/LMSegmentExceptions/ChangePrices/OverrideDetails/${segmentId}`,
        payload,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result;
    },
  };
})();

export const deleteItemException = (() => {
  const name = 'deleteItemException';

  return {
    name,
    query: async (
      payload: {
        itemIds: string[];
      },
      segmentId: number | undefined,
      accessToken: string | undefined
    ) => {
      const req = axios.post(
        `${process.env.REACT_APP_URL}/api/LMSegmentExceptions/ChangePrices/DeleteExceptions/${segmentId}`,
        payload,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const result = await tryFetch(req);

      return result;
    },
  };
})();

export const applyRounding = (() => {
  const name = 'applyRounding';

  return {
    name,
    query: async (
      payload: {
        roundingType: string;
        roundingValue: number;
        itemIds: string[] | undefined;
      },
      segmentId: number | undefined,
      accessToken: string | undefined
    ) => {
      const req = axios.post(
        `${process.env.REACT_APP_URL}/api/LMSegmentExceptions/ApplyRounding/${segmentId}`,
        payload,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

        const result = await tryFetch(req);

        return result;
      },
    };
  })();
     
export const postAnchor = {
  name: 'postAnchor',
  query: async (
    productAnchorRequest: ProductAnchorRequest |  undefined,
    accessToken: string | undefined
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/Segments/Pricing/PostAnchor`,
        productAnchorRequest,
        {
          responseType: 'json',
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

        return res.data;
      } catch (error) {
        console.warn(error);
        throw error;
      }
    },
  };